import { oranje, hemelblauw } from '@dop/ui-primitives/color/colors';

import { BGOVColorTheme } from '../ColorTheme.types';
import { colorThemeBase } from '../globalDefinitions/colorThemeBase';

export const rootColorThemeBGOV: BGOVColorTheme = {
	name: 'Business.gov.nl',
	project: 'BGOV',
	negative: {
		...colorThemeBase.negative,
		primary: {
			normal: oranje[100],
			hover: oranje[130],
			active: oranje[160],
		},
	},
	positive: {
		...colorThemeBase.positive,
		primary: {
			normal: oranje.base,
			hover: oranje[590],
			active: oranje[620],
		},
	},
	text: {
		...colorThemeBase.text,
		primaryContrast: colorThemeBase.text.mutedContrast,
		primary: {
			normal: hemelblauw[850],
			hover: hemelblauw[910],
			active: hemelblauw[970],
		},
	},
};
