import { sharedConfig } from '@dop/shared/constants/sharedConfig';
import { headerFooterColorThemeBGOV } from '@dop/shared/footer/headerFooterColorThemeBGOV';
import { Config } from '@dop/shared/types/config';
import { rootColorThemeBGOV } from '@dop/ui-primitives/colorTheme/bgovDefinitions/rootColorThemeBGOV';

import { providerTypes } from './providerTypes';

export const config: Config = {
	...sharedConfig,
	providerTypes,
	project: 'bgov',
	languageCode: 'en',
	menuPath: '/overview/',
	searchPath: '/search/',
	searchParamQuery: 'query',
	colorThemes: {
		root: rootColorThemeBGOV,
		amendments: rootColorThemeBGOV, // color theme voor wetswijzigingen
		regulations: rootColorThemeBGOV, // color theme voor wetten-en-regels
		subsidies: rootColorThemeBGOV, // color theme voor subsidies
		headerFooter: headerFooterColorThemeBGOV, // color theme voor header and footer
	},
	accessibilityCertificate:
		'http://www.accessibility.nl/inspecties/inspectie/site-1555',
	gtmCode: 'GTM-KN65ZKG',

	localization: {
		locale: 'en-gb',
		timeZone: 'Europe/Amsterdam',
	},

	numberFormatting: {
		thousandSeperationFromLength: 4,
		chunkSize: 3,
		thousandsSeperatorGlyph: ' ',
		decimalGlyph: '.',
	},

	nameSeq: 'Frontend EN',
};
