import { posix as posixPath } from 'path';
import { assert } from 'tsafe';

/**
 * Creates a pathname from a route string and data.
 */
export const compilePathname = <
	Data extends Record<
		string,
		string | number | Array<string | number> | undefined
	>
>(
	routeString: string,
	data: Data
) => {
	assert(
		!routeString.match(/\([^\)]*?\(/),
		`compilePathname does not handle route strings with nested brackets: ${routeString}`
	);
	const pathname = routeString.replace(
		// Matches :any_word123 followed by ?, * or a (string) in between brackets.
		// Known issue: nested brackets are not handled.
		/:(\w[\w\d]*)[\?\*]?(?:\([^\)]*\))?/g,
		(_, key: string) => {
			const value = data[key];
			if (Array.isArray(value)) {
				return value.map(encodeURIComponent).join('/');
			}

			return encodeURIComponent(value ?? '');
		}
	);

	return posixPath.join('/', pathname, '/');
};
