import { getDocument } from '@dop/shared/helpers/windowDocument';
import {
	pushWhenOfHrefKind,
	pushWhenOfType,
} from '@dop/shared/analytics/analyticsHelpers';
import { getScrollEvent } from '@dop/shared/analytics/scrollAnalytics';

import { SCROLL_TRIGGER_POINT_HIT } from '@/globals/pageWrappers/useScrollLogging';
import { getSearchLinkEvent } from '@/globals/analytics/events/pushSearchEvent';
import { decisionTreeResultLinkType } from '../components/decisionTree/decisionTreeConfig';
import { getDecisionTreeResultEvent } from '@/globals/analytics/events/pushDecisionTreeEvent';

const getSocialLinkEvent = ({ href, linkMeta }) => ({
	event: 'interaction.social',
	events: {
		category: 'interaction.social',
		action: linkMeta?.dataset?.socialType,
		label: href,
	},
});
const getExternalLinkEvent = ({ href, linkMeta }) => ({
	event: 'interaction.externalLink',
	events: {
		category: 'interaction.externalLink',
		action: linkMeta?.linkType || 'clickout',
		label: href,
	},
});

const getExternalOrSocialEvent = (action) => {
	return action.linkMeta?.linkType === 'shareMenu'
		? getSocialLinkEvent(action)
		: getExternalLinkEvent(action);
};

const getStaticLinkEvent = ({ href, linkMeta }) => ({
	event: 'interaction.link',
	events: {
		category: 'interaction.link',
		action: linkMeta?.linkType,
		label: href,
	},
});
const getAnchorLinkEvent = ({ text, linkMeta }) => ({
	event: 'interaction.anchorlink',
	events: {
		category: 'interaction.anchorlink',
		action: linkMeta?.linkType || 'click',
		label: text,
	},
});

const getInternalLinkEvent = (action, state) => {
	const { href, linkMeta } = action;
	const linkType = linkMeta?.linkType;

	if (linkType === 'searchResult') {
		// In case of a search-result link, add extra search-data to event
		return getSearchLinkEvent(action, state);
	}
	if (linkType === decisionTreeResultLinkType) {
		// In case of a search-result link, add extra search-data to event
		return getDecisionTreeResultEvent(action);
	}

	return {
		event: 'interaction.link',
		events: {
			category: 'interaction.link',
			action: linkType,
			label: href,
		},
	};
};

export const analyticsMiddleware = (store) => (next) => (action) => {
	if (getDocument()) {
		const state = store.getState();
		pushWhenOfHrefKind('EXTERNAL')(getExternalOrSocialEvent)(action, state);
		pushWhenOfHrefKind('INTERNAL_STATIC_CONTENT')(getStaticLinkEvent)(
			action,
			state
		);
		pushWhenOfHrefKind('INTERNAL_SELF')(getAnchorLinkEvent)(action, state);
		pushWhenOfHrefKind('INTERNAL')(getInternalLinkEvent)(action, state);
		pushWhenOfType(SCROLL_TRIGGER_POINT_HIT)(getScrollEvent)(action, state);
	}

	const result = next(action);

	return result;
};
