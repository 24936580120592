import { decisionTreeResultLinkType } from 'projects/bgov/src/components/decisionTree/decisionTreeConfig';

import { LinkMeta } from '@/globals/routing/location/locationChangeActions';
import { pushEvent } from '../pushEvent';

// Events & helpers for decisionTree (startupbox on BGOV)
const decisionTreeEventName = 'interaction.decisionTree';

export const pushDecisionTreeStartedEvent = () => {
	pushEvent({
		events: {
			category: decisionTreeEventName,
			action: 'start',
		},
	});
};

export const pushDecisionTreeQuestionAnsweredEvent = ({
	questionText,
	answerText,
}: {
	questionText: string;
	answerText: string;
}) => {
	pushEvent({
		events: {
			category: decisionTreeEventName,
			action: questionText,
			label: answerText,
		},
	});
};

export const getDecisionTreeResultEvent = ({
	linkMeta,
}: {
	linkMeta?: LinkMeta;
}) => {
	if (linkMeta?.linkType !== decisionTreeResultLinkType) return null;

	const resultText = linkMeta.dataset?.question || '';

	return {
		event: decisionTreeEventName,
		events: {
			category: decisionTreeEventName,
			action: 'result',
			label: resultText,
		},
	};
};
