import { configureStore } from '@reduxjs/toolkit';

import { analyticsMiddleware } from 'moduleAlias/project/src/middleware/analyticsMiddleware';
import { combinedJSReducers } from '@dop/shared/reducers/combinedJSReducers';

export const store = configureStore({
	reducer: combinedJSReducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({}).concat(analyticsMiddleware),
});

export type ReduxDispatch = typeof store.dispatch;
export type ReduxRootState = ReturnType<typeof store.getState>;
