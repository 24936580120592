'use client';

import { AllProviderTypes } from '@/globals/config/providerTypes';
import { useLastPagePathnameBeforeMenu } from '@/globals/pageWrappers/usePageHistory';
import { DOPLink } from '@/uiComposites/interactive/DOPLink';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import {
	addTestSelector,
	testSelectorAttribute,
} from '@dop/shared/helpers/testSelector';
import { useSelectedLayoutSegments } from 'next/navigation';
import {
	ComponentPropsWithoutRef,
	MouseEventHandler,
	ReactElement,
	ReactNode,
} from 'react';

type ReturnLinkProps = {
	openedSlot: ReactElement;
	openedProps: Omit<ComponentPropsWithoutRef<typeof DOPLink>, 'href'>;
	closedSlot: ReactElement;
	closedProps: Omit<ComponentPropsWithoutRef<typeof DOPLink>, 'href'>;
	href: string;
	className?: string;
	id?: string;
};
const ReturnLink = ({
	href,
	onClick,
	returnHREF,
	openedProps: { className: openedClassName, ...openedProps },
	openedSlot,
	closedProps: { className: closedClassName, ...closedProps },
	closedSlot,
	className,
	id,
	isOpened,
	[testSelectorAttribute]: dataUITest,
}: ReturnLinkProps & {
	returnHREF: string;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
	isOpened: boolean;
	[testSelectorAttribute]?: string;
}) => {
	return (
		<DOPLink
			id={id}
			{...(isOpened ? openedProps : closedProps)}
			className={classes(
				className,
				isOpened ? openedClassName : closedClassName
			)}
			href={isOpened ? returnHREF : href}
			onClick={onClick}
			{...addTestSelector(dataUITest)}
		>
			{isOpened ? openedSlot : closedSlot}
		</DOPLink>
	);
};

export const ReturnLinksClient = ({
	menu,
	search,
}: {
	menu: ReturnLinkProps & { providerTypes: Array<AllProviderTypes> };
	search: ReturnLinkProps & { providerTypes: Array<AllProviderTypes> };
}) => {
	const returnHREF = useLastPagePathnameBeforeMenu() ?? '/';
	const segments = useSelectedLayoutSegments();

	const segment = segments[0] === 'legacy' ? segments[2] : segments[0];

	const isMenuOpened =
		segment != null && (menu.providerTypes as Array<string>).includes(segment);
	const isSearchOpened =
		segment != null &&
		(search.providerTypes as Array<string>).includes(segment);

	const revertWhenMenuOpened = (nodes: Array<ReactNode>) => {
		if (isMenuOpened) {
			return [...nodes].reverse();
		}
		return nodes;
	};

	return (
		<>
			{revertWhenMenuOpened([
				<ReturnLink
					key={'menu'}
					{...menu}
					returnHREF={returnHREF}
					isOpened={isMenuOpened}
					data-ui-test="uiHeaderBarButton-Menu"
				/>,
				<ReturnLink
					key={'search'}
					{...search}
					returnHREF={returnHREF}
					isOpened={isSearchOpened}
					data-ui-test="uiHeaderBarButton-Search"
				/>,
			])}
		</>
	);
};
